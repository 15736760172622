import React from 'react';
import Posts from '../components/posts';
import * as b from "../components/bootstrap.module.css"
import Grid from '../components/grid'
import Breadcrumbs from "../components/breadcrumbs"
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BlogContent from '../components/blog-content';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Articles = ({ 
  data: { allMysqlBlog, mysqlTerms }, 
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  const { title, description } = mysqlTerms;

  return (
  <Layout pathname={pathname}>
    <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(' ')}>
       <Breadcrumbs theme='white' items={breadcrumbs} /> 
    </div>
    <div className={[b.container, b.pt3,b.ptLg3,b.pb5,'blue-font'].join(' ')}>
      {title && <h1 className={["big-title",'blackToWhite'].join(' ')}>{title}</h1>}
      {description && <BlogContent content={description} />}
      
      {allMysqlBlog.nodes.length > 0 && (
        <div className='articles'>
          <Posts items={allMysqlBlog.nodes} />
        </div>
      )}
    </div>
  </Layout>
)}

export default Articles;

export const query = graphql`
  query ArticleQuery($mysqlId: Int!) {
    mysqlTerms: mysqlTerms(mysqlId: { eq: $mysqlId }) {
      title
      description
    }
    allMysqlBlog(filter: {status: {eq: "publish"}, term_id: {eq: $mysqlId}}) {
      nodes {
        ...PostData
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`